<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <el-button type="warning" @click="addFleet()" :loading="submitLoading"
              >新增</el-button
            >
            <el-button
              type="success"
              @click="$router.push('/vehicle-management/assign-fleet')"
              >分配车队</el-button
            >
          </div>
        </ayl-table>
      </div>
    </div>

    <!-- 新增 -->
    <el-dialog
      title="新增车队"
      :visible.sync="centerDialogVisible"
      width="800px"
      top="none"
      center
    >
      <el-form :model="form" :rules="rules" ref="form" :inline="true">
        <el-form-item label="所属公司" :label-width="formLabelWidth">
          <el-input v-model="form.company" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="车队名称" :label-width="formLabelWidth" prop="teamName">
          <el-input
            v-model="form.teamName"
            autocomplete="off"
            placeholder="请输入"
            maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item label="车队负责人" :label-width="formLabelWidth" prop="contacts">
          <el-input
            v-model="form.contacts"
            autocomplete="off"
            placeholder="请输入"
            maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" :label-width="formLabelWidth" prop="contactPhone">
          <el-input
            v-model="form.contactPhone"
            autocomplete="off"
            placeholder="请输入"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="车队业务" :label-width="formLabelWidth" prop="teamBusiness">
          <el-input
            v-model="form.teamBusiness"
            autocomplete="off"
            placeholder="请输入"
            maxlength="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            v-model="form.carTeamRemark"
            autocomplete="off"
            placeholder="请输入(选填)"
            maxlength="64"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="submitLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑"
      :visible.sync="editDialogVisible"
      width="800px"
      top="none"
      center
    >
      <el-form :model="form" :rules="rules" ref="form" :inline="true">
        <el-form-item label="所属公司" :label-width="formLabelWidth">
          <el-input v-model="form.company" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="车队名称" :label-width="formLabelWidth" prop="teamName">
          <el-input
            v-model="form.teamName"
            autocomplete="off"
            placeholder="请输入"
            maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item label="车队负责人" :label-width="formLabelWidth" prop="contacts">
          <el-input
            v-model="form.contacts"
            autocomplete="off"
            placeholder="请输入"
            maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" :label-width="formLabelWidth" prop="contactPhone">
          <el-input
            v-model="form.contactPhone"
            autocomplete="off"
            placeholder="请输入"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="车队业务" :label-width="formLabelWidth" prop="teamBusiness">
          <el-input
            v-model="form.teamBusiness"
            autocomplete="off"
            placeholder="请输入"
            maxlength="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            v-model="form.carTeamRemark"
            autocomplete="off"
            placeholder="请输入(选填)"
            maxlength="64"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSubmitForm" :loading="submitLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 车队详情 -->
    <el-dialog
      top="15vh"
      :title="fleetName"
      :visible.sync="ordersDialogVisible"
      width="60%"
      center
    >
      <div class="content-main">
        <ayl-table :table="fleetList"> </ayl-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;
    const validateContactPhone = (rule, value, callback) => {
      if (!value || !/^[1][0-9][0-9]{9}$/.test(value)) {
        callback(new Error("请输入11位手机号码"));
      } else {
        callback();
      }
    };
    return {
      fleetName: null,
      ordersDialogVisible: false,

      centerDialogVisible: false, //新增车队
      editDialogVisible: false, //编辑车队
      submitLoading: false,
      formLabelWidth: "150px",
      form: {
        company: null,
        companyId: null,
        teamName: null, //车队名称
        contacts: null, //车队负责人
        contactPhone: null, //手机号码
        teamBusiness: null, //车队业务
        carTeamRemark: null, //备注
        groupId: null,
      },
      rules: {
        teamName: [
          { max: 16, required: true, message: "请输入车队名称", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        contacts: [
          { max: 26, required: true, message: "请输入车队负责人", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        contactPhone: [
          { required: true, trigger: "blur", validator: validateContactPhone },
        ],
        teamBusiness: [
          { max: 32, required: true, message: "请输入车队业务", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
      },
      nav: [
        { name: "车辆管理", path: "" },
        { name: "车队管理", path: "" },
      ],
      table: {
        api: vm.$api.listCarTeamInfoByQueryCondition,
        query: {
          queryContent: null,
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "车队名称/车辆负责人",
          },
        ],
        columns: [
          {
            title: "车队名称",
            key: "teamName",
            showTooltip: true,
          },
          {
            title: "负责人",
            width: "120px",
            key: "contacts",
          },
          {
            title: "手机号码",
            width: "160px",
            key: "contactPhone",
          },
          {
            title: "车队业务",
            width: "120px",
            key: "teamBusiness",
          },
          {
            title: "车辆数",
            width: "100px",
            key: "carCount",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view": true,
                    },
                    on: {
                      click: vm.onDetails.bind(this, ctx.row),
                    },
                  },
                  ctx.row.carCount
                ),
              ]);
            },
          },
          {
            title: "所属公司",
            key: "company",
            showTooltip: true,
          },
          {
            title: "操作",
            width: "155px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view": true,
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row),
                    },
                  },
                  "编辑"
                ),
                h(
                  "span",
                  {
                    class: {
                      "table-delete": true,
                    },
                    on: {
                      click: vm.delete.bind(this, ctx.row),
                    },
                  },
                  "删除"
                ),
              ]);
            },
          },
        ],
      },
      fleetList: {
        api: vm.$api.vehicleManagement_carTeamList,
        query: {
          groupId: null,
        },
        columns: [
          {
            title: "车牌号",
            showTooltip: true,
            key: "plateNumber",
          },
          {
            title: "所属公司",
            showTooltip: true,
            key: "company",
          },
          {
            title: "品牌型号",
            showTooltip: true,
            key: "carBrand",
          },
          {
            title: "车辆状态",
            showTooltip: true,
            key: "driverName",
            render: (h, ctx) => {
              let text = {
                '0': "设备运行",
                '1': "设备临时运行",
                '2': "设备待机",
                '3': "网络离线",
                '4': "异常断电",
                '5': "设备故障",
                '6': "初始状态",
              }[ctx.row.driverName];
              if(text===undefined){
                return h("span", '- -');
              }else {
                return h("span", text);
              }
            },
          },
          // {
          //   title: '联系电话',
          //   showTooltip: true,
          //   key: 'contactPhone',
          // }
        ],
      },
    };
  },
  watch: {
    fleetList() {
      this.ordersDialogVisible = true;
    },
  },
  methods: {
    // 详情
    async onDetails(val) {
      this.fleetName = val.teamName;
      this.ordersDialogVisible = true;
      this.fleetList.query.groupId = val.groupId;
      // await this.$nextTick(() => {
      //   this.$search(this.fleetList)
      // });
    },

    async delete(val) {
      await this.$confirm(`您确认删除【${val.teamName}】吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      /**
       * 删除单条车队信息
       */
      await this.$api.DeleteMotorcadeItem({
        groupIdList: [val.groupId],
      });
      this.$notify({
        title: "成功",
        message: "删除成功",
        type: "success",
      });
      await this.$search(this.table);
    },
    async addFleet() {
      let companyId = await this.$api.companyList({
        queryContent: this.form.company,
        currentPage: 1,
        pageSize: 10,
      });
      this.form.companyId = companyId.data[0].companyId;
      this.centerDialogVisible = true;
    },
    async addSubmitForm() {
      await this.$api.AddMotorcade({
        carTeamInfoVOIOV: {
          company: this.form.companyId,
          teamName: this.form.teamName,
          contacts: this.form.contacts,
          contactPhone: this.form.contactPhone,
          teamBusiness: this.form.teamBusiness,
          carTeamRemark: this.form.carTeamRemark,
        },
      });
      this.$notify({
        title: "成功",
        message: "新增成功",
        type: "success",
      });
      this.centerDialogVisible = false;
      await this.$search(this.table);
    },

    //编辑
    async goEdit(val) {
      this.form.groupId = val.groupId;
      let groupList = await this.$api.getCarTeamInfoByGroupId({
        groupId: val.groupId,
        tenantId: val.tenantId,
      });
      (this.form.teamName = groupList.teamName),
        (this.form.contacts = groupList.contacts),
        (this.form.contactPhone = groupList.contactPhone),
        (this.form.teamBusiness = groupList.teamBusiness),
        (this.form.carTeamRemark = groupList.carTeamRemark);

      let companyId = await this.$api.companyList({
        queryContent: this.form.company,
        currentPage: 1,
        pageSize: 10,
      });
      this.form.companyId = companyId.data[0].companyId;
      this.editDialogVisible = true;
    },
    async editSubmit() {
      await this.$api.UpdateMotorcadeInfo({
        carTeamInfoVOIOV: {
          groupId: this.form.groupId,
          company: this.form.companyId,
          teamName: this.form.teamName,
          contacts: this.form.contacts,
          contactPhone: this.form.contactPhone,
          teamBusiness: this.form.teamBusiness,
          carTeamRemark: this.form.carTeamRemark,
        },
      });
      this.$notify({
        title: "成功",
        message: "修改成功",
        type: "success",
      });
      this.editDialogVisible = false;
      await this.$search(this.table);
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this.submitLoading = true;
        try {
          this.addSubmitForm();
        } catch (e) {}
        this.submitLoading = false;
      });
    },
    editSubmitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this.submitLoading = true;
        try {
          this.editSubmit();
        } catch (e) {}
        this.submitLoading = false;
      });
    },
  },
  async mounted() {
    await this.$search(this.table);
    let companyList = await this.$api.listCompanyInfoForAddCarTeam({});
    this.form.company = companyList[0].company;
  },
};
</script>

<style lang="sass" scoped>
.content-box /deep/ .el-form-item
    width: 50%
    display: inline-block

/deep/ .el-dialog__header
    border-bottom: 1px solid $grayShallow3

/deep/.el-date-editor.el-input
    width: 160px
/deep/ .el-form
  display: flex
  flex-wrap: wrap
  margin-top: 30px
/deep/ .el-input.is-disabled .el-input__inner
  olor: #606266;
/deep/ .el-dialog__wrapper
  display: flex;
  justify-content: center;
  align-items: center;
</style>
